<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-8 col-lg-8">
            <h4 class="page-title">{{ $t("incidence_in.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("incidence_in.home") }}</a>
                </li>
                  <li class="breadcrumb-item">
                  <a href="#"> {{$t('title.title_inci')}} </a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>

          <div class="col-md-4 col-lg-4 text-right">
            <button
              class="btn btn-outline-success"
              v-if="showAction(4)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-alert-triangle"></i> {{ $t(title) }}
                    </h5>
                  </div>
                </div>
              </div>
              <div v-show="showAction(1)">
                <div class="p-2" id="list" v-if="showAction(1)">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="list"
                    :search="search"
                    height="auto"
                    fixed-header
                    :footer-props="{
                      'items-per-page-text': $t('table.item_table'),
                      'items-per-page-all-text': $t('table.all'),
                      'items-per-page-options': [15, 30, 50, 100],
                    }"
                    :loading-text="$t('table.loading')"
                    :no-data-text="$t('table.no_results_available')"
                    :no-results-text="$t('table.no_results_search')"
                    >
                    <template v-slot:[`item.reason`]="{ item }">
                      <div>
                        <small>
                          {{ item.reason }}
                        </small>
                      </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <div
                        v-if="withPermissionName(permissions.INC_STA_DETA_01)"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="ml-3 hand"
                              @click="view(item)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <i class="feather icon-eye"></i>
                            </span>
                          </template>
                          <span>{{ $t("table.detail") }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:top>
                      <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6 ">
                          <v-text-field
                            v-model="search"
                            :label="$t('table.search')"
                          ></v-text-field>
                        </div>
                      </div>
                    </template>
                  </v-data-table>
                  <ButtonPrintComponent />
                </div>
              </div>

              <div v-if="showAction(2) || showAction(3) || showAction(4)">
                <div class="card">
                  <div class="card-body">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="doAction">
                        <div class="row p-2">
                          <div class="col-md-3">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.idIncidence"
                              :label="$t('label.id_inciden')"
                              required
                              autocomplete="off"
                            >
                            </v-text-field>
                          </div>
                          <div class="col-md-3">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.typeIncidenceName"
                              :label="$t('label.type_incidence')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </div>
                          <div class="col-md-3" v-if="model.idOrder">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.idOrder"
                              :label="$t('label.order_id')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </div>

                          <div class="col-md-3" v-if="model.idStock">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.idStock"
                              :label="$t('label.id_inciden_stock')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </div>
                          <div class="col-md-3" v-if="model.lot">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.lot"
                              :label="$t('label.lot')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row p-2">
                          <div class="col-md-8">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.fullNameOrigin"
                              :label="$t('label.place_incidence')"
                              required
                              autocomplete="off"
                              append-icon="mdi-map-marker"
                            ></v-text-field>
                          </div>
                          <div class="col-md-4">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.dateIncidence"
                              :label="$t('label.incident_date*')"
                              required
                              autocomplete="off"
                              append-icon="mdi-calendar"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <v-textarea
                              outlined
                              v-model="model.infoProduct"
                              disabled
                              counter
                              :label="$t('label.product_information*')"
                              rows="3"
                              class="mx-2"
                            ></v-textarea>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <v-textarea
                              outlined
                              v-model="model.reason"
                              disabled
                              counter
                              :label="$t('label.reason')"
                              class="mx-2"
                              oninput="this.value = this.value.toUpperCase()"
                              append-icon="mdi-comment"
                            ></v-textarea>
                          </div>
                        </div>

                        <div class="col-md-12 text-right">
                          <button
                            class="btn btn-success"
                            v-if="!showAction(4)"
                            type="submit"
                            :disabled="invalid || sending"
                          >
                            <i class="feather icon-save mr-1"></i> {{ $t("global.save") }}
                          </button>
                        </div>
                      </form>
                    </validation-observer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Rightbar -->
  </div>
  <!-- End Containerbar -->
  <!-- Start js -->
</template>

<script>
// Vuex
import { mapState, mapMutations } from "vuex";
import { IncidenceOrderRequest, ExcelRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { INCIDENCE_ORDER_IN } from "@/core/DataTableHeadersLocale";
import { UtilFront, PermissionConstants } from "@/core";
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";

export default {
  data() {
    return {
      permissions: PermissionConstants,
      title: "incidence_in.incoming_incidents",
      list: [],
      headers: INCIDENCE_ORDER_IN(),
      search: "",
      action: 1,
      sending: false,
      dialog: false,
      isView: false,
      listTwo: [],
      searchTwo: "",
      model: {},
      branchOffice: {},
      branchOffices: [],
      changeStatus: {
        question: "",
        description: "",
      },
      loading: false,
    };
  },
  async created() {
    this.branchOffice = this.branchOfficeState;
    this.updateAction(1);
    this.action = 1;
    await this.listAll();
  },
  computed: {
    // Extración del state de auth para la sucursal global
    ...mapState("auth", { branchOfficeState: "branchOffice" }),
    titleAction() {
      if (this.action == 1) {
        return "Inicidencias";
      } else if (this.action == 2) {
        return "Inicidencias";
      } else if (this.action == 3) {
        return "Inicidencias";
      } else if (this.action == 4) {
        return "Información de incidencia";
      }
      return "Inicidencias";
    },
  },
  methods: {
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {};
        this.action = action;
      } else {
        this.action = action;
        this.isView = action == 4;
      }
      this.updateAction(action);
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    async listAll() {
      this.loading = UtilFront.getLoadding();
      this.list = [];
      let data = {
        id: this.branchOffice.idBranchoffice,
      };
      await IncidenceOrderRequest.listAll(data)
        .then((res) => {
          let { data } = res.data;
          if (data.objects) {
            this.list = data.objects.map((obj) => {
              if (obj.branchOffice) {
                obj.fullNameOrigin = `(${obj.branchOffice.branchOfficeKey}) ${obj.branchOffice.name} `;
              }
              obj.infoProduct = `${obj.productKey} - ${obj.productName}; ${obj.productDescription}`;
              obj.idStock = obj.idStock || "";
              obj.idOrder = obj.idOrder || "";
              obj.lot = obj.lot || "";
              return obj;
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    view(object) {
      Object.assign(this.model, object);
      this.changeAction(4);
    },
    listAllByBranchOffice() {
      this.list = [];
      IncidenceOrderRequest.listAllByBranchOffice(
        this.branchOffice.idBranchoffice
      )
        .then((res) => {
          let { data } = res.data;
          if (data.objects) {
            this.list = data.objects.map((obj) => {
              if (obj.origin) {
                obj.fullNameOrigin = `(${obj.origin.branchOfficeKey}) ${obj.origin.name} `;
              }
              return obj;
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {});
    },
    excel() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportIncidenceIn({
        id: this.branchOffice.idBranchoffice,
      });
    },
  },
  watch: {
    async branchOfficeState() {
      this.branchOffice = this.branchOfficeState;
      await this.listAll();
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.headers = INCIDENCE_ORDER_IN();
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ButtonPrintComponent,
  },
};
</script>

<style>
.theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgb(0 138 194) !important;
}
/*Código para modificar los botones */
@media (max-width: 576px) {
  .controlSize {
    text-align: center;
    float: right;
  }
  /*Código para modificar las barras de fecha o botones de busqueda*/
  .selectSize {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (min-width: 992px) {
  .controlSize {
    float: right;
  }
}
</style>
